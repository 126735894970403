import React from 'react';
import { IAuthResponse } from '@models/shared/i-auth-response';

export enum MODES {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGIN_ONLY = 'LOGIN_ONLY',
}

export interface ILoginRegisterModal {
  closable?: boolean;
  initialMode?: MODES;
  onClose?: () => void;
  onLoginSuccess: (user:IAuthResponse) => void;
  onRegisterSuccess: (user:IAuthResponse) => void;
  source: string;
  title?: string;
  loginTitle?: string;
  registerTitle?: string;
  allowSignup?: boolean;
}

export interface ILoginRegisterModalContext {
  open: (state: ILoginRegisterModal) => void;
}

/* istanbul ignore next */
const LoginRegisterModalContext = React.createContext<ILoginRegisterModalContext>({
  open: () => null,
});

export default LoginRegisterModalContext;
